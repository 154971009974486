<template>
    <div class="g_notification_main">
        <div class="row align-items-center">
            <div class="col-sm">
                <h2 class="main_heading">
                    <button type="button" class="btn back_btn" tapable @click="$router.go(-1)">
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>Notifications
                </h2>
            </div>
            <!-- <div class="col-sm-auto mt-3 mt-sm-0 ml-sm-auto">
                <button class="btn btn-primary clear_all_btn" @click="readAllNotificationMethod">Read All</button>
            </div> -->
        </div>
        <!-- <div class="row">
            <div class="col-sm-12 text-right pt-3">
                <div class="switch_for_status_button">
                    <div class="onoffswitch">
                        <input type="checkbox" name="onoffswitch" id="statusChangeOfRead" class="onoffswitch-checkbox"
                            v-model="isReadState" :checked="isReadState">
                        <label for="statusChangeOfRead" class="onoffswitch-label" @click="isReadChangeMethod">
                            <span class="onoffswitch-inner"></span>
                            <span class="onoffswitch-switch"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="g_notification_main_body">
            <div class="g_notification_page_wrp mt-4 coming_soon">
                <table>
                    <tbody>
                        <tr><td class="text-center">Coming Soon</td></tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="g_notification_page_wrp mt-4 announcements_section">
                <div class="g_notification_heading_wrp">
                    <h2 class="g_notification_heading">Announcements</h2>
                </div>
                <table>
                    <tbody v-if="getGlobalNotificationData">
                        <template v-if="getGlobalNotificationDataResults.announcement.length > 0">
                            <tr v-for="item in getGlobalNotificationDataResults.announcement" :key="item.id">
                                <template v-if="item.is_read">
                                    <td width="100%" class="gray_out">
                                        <h2 class="notifiaction_title" v-html="item.notification"></h2>
                                        <div class="notification_date">{{ item.created_at | LocalDateFormat }}
                                            {{ item.created_at | LocalTimeFormat }}</div>
                                    </td>
                                </template>
                                <template v-else>
                                    <td width="70%">
                                        <h2 class="notifiaction_title" v-html="item.notification"></h2>
                                        <div class="notification_date">{{ item.created_at | LocalDateFormat }}
                                            {{ item.created_at | LocalTimeFormat }}</div>
                                    </td>
                                    <td width="30%">
                                        <button class="btn btn-danger dismiss-notification"
                                            @click="readNotificationByIdmethod(item.id)">Read</button>
                                    </td>
                                </template>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="2">You don't have any announcements notification yet.</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="g_notification_page_wrp mt-4 messages_section">
                <div class="g_notification_heading_wrp">
                    <h2 class="g_notification_heading">Connections</h2>
                </div>
                <table>
                    <tbody v-if="getGlobalNotificationData">
                        <template v-if="getGlobalNotificationDataResults.connection_request.length > 0">
                            <tr v-for="item in getGlobalNotificationDataResults.connection_request" :key="item.id">
                                <template v-if="item.is_read">
                                    <td width="100%" class="gray_out">
                                        <h2 class="notifiaction_title" v-html="item.notification"></h2>
                                        <div class="notification_date">{{ item.created_at | LocalDateFormat }}
                                            {{ item.created_at | LocalTimeFormat }}</div>
                                    </td>
                                </template>
                                <template v-else>
                                    <td width="70%">
                                        <h2 class="notifiaction_title" v-html="item.notification"></h2>
                                        <div class="notification_date">{{ item.created_at | LocalDateFormat }}
                                            {{ item.created_at | LocalTimeFormat }}</div>
                                    </td>
                                    <td width="30%">
                                        <button class="btn btn-danger dismiss-notification"
                                            @click="readNotificationByIdOfReqMethod(item.id)">View Request</button>
                                    </td>
                                </template>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="2">You don't have any connection request notification yet.</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="g_notification_page_wrp mt-4 connections_section">
                <div class="g_notification_heading_wrp">
                    <h2 class="g_notification_heading">Reminders</h2>
                </div>
                <table>
                    <tbody v-if="getGlobalNotificationData">
                        <template v-if="getGlobalNotificationDataResults.reminders.length > 0">
                            <tr v-for="item in getGlobalNotificationDataResults.reminders" :key="item.id">
                                <template v-if="item.is_read">
                                    <td width="100%" class="gray_out">
                                        <h2 class="notifiaction_title" v-html="item.notification"></h2>
                                        <div class="notification_date">{{ item.created_at | LocalDateFormat }}
                                            {{ item.created_at | LocalTimeFormat }}</div>
                                    </td>
                                </template>
                                <template v-else>
                                    <td width="70%">
                                        <h2 class="notifiaction_title" v-html="item.notification"></h2>
                                        <div class="notification_date">{{ item.created_at | LocalDateFormat }}
                                            {{ item.created_at | LocalTimeFormat }}</div>
                                    </td>
                                    <td width="30%">
                                        <button class="btn btn-danger dismiss-notification"
                                            @click="readNotificationByIdmethod(item.id)">Read</button>
                                    </td>
                                </template>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="2">You don't have any reminders yet.</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div> -->
        </div>
    </div>
</template>
<script>
/*eslint-disable */

import { bus } from '@/main';
import {
  mapGetters,
} from 'vuex';
import toast from '@/services/toast';

export default {
  name: 'global-notification-page',
  components: {},
  props: [],
  data () {
    return {
      getGlobalNotificationData:{},
      getGlobalNotificationDataResults:
        {
            announcement:[],
            connection_request:[],
            reminders:[],
        },
      isReadState:false
    }
  },
  computed: {
    // ...mapGetters([
    //   'getGlobalNotificationGetters'
    // ]),
    // getGlobalNotificationInfo(){
    //   this.getGlobalNotificationData = this.$store.getters.getGlobalNotificationGetters.data;
    //   return
    // },
    // setInitialStates(){
    //   if(this.getGlobalNotificationData){
    //     this.getGlobalNotificationDataResults = this.getGlobalNotificationData.result
    //   }
    // }
  },
  mounted () {
    bus.$emit('clearNotificationBadgeInfo', null);
    // this.getGlobalNotificationInfo
    // this.setInitialStates
  },
  methods: {
    readNotificationByIdOfReqMethod(itemId){
      let data = {
        id : itemId,
        is_read: 'True'
      }
      this.$store.dispatch('readNotificationByIdAction', data).then((res) => {
        this.$store.dispatch('chatBroserNotificationFun').then((res) => {
          this.$router.push({name:'saChatRequestList'})
        })
      })
    },
    readNotificationByIdmethod(itemId){
      let data = {
        id : itemId,
        is_read: 'True'
      }
      this.$store.dispatch('readNotificationByIdAction', data).then((res) => {
        this.$store.dispatch('getGlobalNotificationMethod',  {flag:'False'})
        toast.success('Notification is marked as read!');
      })
    },
    isReadChangeMethod(){
      if(this.isReadState){
        this.$store.dispatch('getGlobalNotificationMethod', {flag:'False'})
      }else{
        this.$store.dispatch('getGlobalNotificationMethod', {flag:'True'})
      }
    },
    readAllNotificationMethod(){
      this.$store.dispatch('patchGlobalNotificationReadMethod').then((res) => {
        this.$store.dispatch('getGlobalNotificationMethod',  {flag:'False'})
        toast.success(res.data);
      }).catch((err) => {
        toast.error(err.data.msg);
      })
    }
  },
  watch: {
    // getGlobalNotificationInfo(){
    //   return this.$store.getters.getGlobalNotificationGetters;
    // },
    // setInitialStates(){
    //   if(this.getGlobalNotificationData){
    //     this.getGlobalNotificationDataResults = this.getGlobalNotificationData.result
    //   }
    // }
  },
}
</script>

<style lang="scss" scoped>
.g_notification_main {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

    .main_heading {
        color: #ffffff;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: bold;
        margin: 0px;
    }


    .btn-danger {
        cursor: pointer;
        position: relative;
        min-width: 75px;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        background: none;
        color: #ffd400;
        border: 2px solid #ffd400 !important;
        border: none;
        border-radius: 3px;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        font-size: 18px;
        line-height: 32px;
        padding: 0px 12px;

        &:hover {
            background: #ffd400;
            color: #000;
            border-color: #ffd400 !important;
        }
    }

    .g_notification_page_wrp {
        color: #fffcc4;
        @media only screen and (max-width:767px){
            &.coming_soon{border:1px solid #ffd400;}
        }
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0 3px;

            tr {
                td {
                    color: #ffd400;
                    padding: 10px;
                    font-size: 22px;
                    font-family: 'MyriadProRegular', 'Lato', sans-serif;
                    transition: all ease 0.5s;
                    -webkit-transition: all ease 0.5s;
                    -moz-transition: all ease 0.5s;
                    border-top: 0px;
                    background: rgba(228, 233, 237, 0.1);
                    margin-top: 5px;

                    .notifiaction_title {
                        font-size: 18px;
                        margin: 0px;
                    }

                    &.notification-invitation {
                        color: #90CAF9;
                    }

                    &.notification-warning {
                        color: #FFE082;
                    }

                    &.notification-danger {
                        color: #FFAB91;
                    }

                    &.notification-reminder {
                        color: #CE93D8;
                    }

                    &.gray_out {
                        background: rgba(128, 128, 128, 0.4) !important;
                    }

                    @media only screen and (max-width:767px) {
                        font-size: 16px;
                    }
                }

                td:nth-child(2) {
                    text-align: right;
                }
            }
        }

        .g_notification_heading {
            font-size: 24px;
            font-weight: 500;
        }

        &.admin_action_section {
            color: #ffbf74;

            table tr td {
                background: rgba(255, 207, 152, 0.16);
                color: #fffcc4;
            }
        }

        &.announcements_section {
            color: #ff907f;

            table tr td {
                background: rgba(255, 179, 168, 0.16);
                color: #fffcc4;
            }
        }

        &.connections_section {
            color: #9cbef9;

            table tr td {
                background: rgba(135, 179, 255, 0.16);
                color: #fffcc4;
            }
        }

        &.messages_section {
            color: #42e344;

            table tr td {
                background: rgba(122, 255, 124, 0.16);
                color: #fffcc4;
            }
        }

        .notification_date {
            font-size: 16px;
            margin-top: 5px;
            line-height: 1;
            color: #717171;
        }
    }

    .switch_for_status_button {
        display: inline-block;

        .onoffswitch {
            position: relative;
            width: 90px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            transition: all ease 0.5s;
            -webkit-transition: all ease 0.5s;
            -moz-transition: all ease 0.5s;

            .onoffswitch-checkbox {
                display: none;
            }

            .onoffswitch-label {
                display: block;
                overflow: hidden;
                cursor: pointer;
                border: 0px solid #999999;
                border-radius: 20px;
                margin: 0px;
            }

            .onoffswitch-inner {
                display: block;
                width: 200%;
                margin-left: -100%;
                -webkit-transition: margin 0.3s ease-in 0s;
                transition: margin 0.3s ease-in 0s;

                &::before,
                &::after {
                    display: block;
                    float: left;
                    width: 50%;
                    height: 25px;
                    padding: 0;
                    line-height: 25px;
                    font-size: 16px;
                    color: white;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    font-family: 'MyriadProRegular', 'Lato', sans-serif;
                    letter-spacing: 0.5px;
                    transition: all ease 0.5s;
                    -webkit-transition: all ease 0.5s;
                    -moz-transition: all ease 0.5s;
                }

                &::before {
                    content: "Read";
                    padding-left: 15px;
                    background-color: #36B04C;
                    color: #FFFFFF;
                    text-align: left;
                }

                &::after {
                    content: "Unread";
                    padding-right: 10px;
                    background-color: #6c757d;
                    color: #FFFFFF;
                    text-align: right;
                }
            }

            .onoffswitch-switch {
                display: block;
                width: 17px;
                height: 17px;
                margin: 4px;
                background: #FFFFFF;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 63px;
                border: 0px solid #999999;
                border-radius: 13px;
                transition: all ease 0.5s;
                -webkit-transition: all ease 0.5s;
                -moz-transition: all ease 0.5s;
            }

            .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
                right: 0px !important;
            }

            .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
                margin-left: 0;
            }
        }
    }
}
</style>